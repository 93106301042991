<template>
  <svg-wrapper :is-in-nav="isInNav">
    <component
      :is="currentFigureComponent"
      :current="buildingData"
      :buildingHeight="buildingData.height"
      :is-in-nav="isInNav"
    ></component>
  </svg-wrapper>
</template>

<script>
import { mapState } from 'vuex'
import { formsComponents } from '@/utils'
import SvgWrapper from '@/components/dump/Figures/SvgWrapper'
export default {
  props: {
    sectorIndex: {
      type: Number,
      required: true,
      default: 0
    },
    isInNav: {
      type: Boolean
    }
  },
  name: 'NavImage',
  components: { SvgWrapper },
  computed: {
    ...mapState({
      sectors: state => state.sectors
    }),
    sectorFromState() {
      return this.sectors[this.sectorIndex]
    },
    currentFigureComponent() {
      return formsComponents[this.buildingData.form]
    },
    buildingData() {
      return this.sectorFromState.building
    }
  }
}
</script>

<style scoped></style>
