<script>
import { defaultSvgParams } from '@/utils/drawing'

export default {
  name: 'SvgWrapper',
  props: {
    isNeedCode: {
      type: Boolean
    },
    isInNav: {
      type: Boolean
    }
  },
  render(createElement) {
    const { width, height } = defaultSvgParams
    return createElement(
      'svg',
      {
        ref: 'figureCode',
        class: 'svg-wrapper',
        attrs: {
          width,
          height,
          viewBox: `0 0 ${width} ${height}`
        }
      },
      this.$slots.default
    )
  },
  methods: {
    async updateFigureCode() {
      await this.$nextTick()
      await this.$nextTick()
      // navigator.clipboard.writeText(this.$refs.figureCode.outerHTML)
      this.$emit('updateFigureCode', btoa(this.$refs.figureCode.outerHTML))
    }
  },

  updated() {
    if (this.isNeedCode) {
      this.updateFigureCode()
    }
  },
  mounted() {
    if (this.isNeedCode) {
      this.updateFigureCode()
    }
  }
  // beforeDestroy() {
  //   if (this.isNeedCode) {
  //     this.updateFigureCode()
  //   }
  // }
}
</script>
<style lang="sass">
.svg-wrapper
  max-width: 100%
  height: auto
  .transparent > *
    stroke-width: 0
    fill: transparent
</style>
